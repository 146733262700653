import React from 'react';
import '../styles/Logo.css';  // Import styles for the logo

function Logo() {
  return (
    <div className="logo-container">
      <div className="logo">
        <div className="title-container">
          <span className="title_song">SONG</span>
          <span className="title_SongShakeMilkshake">
            <img src="/assets/images/SongShakeMilkshake.png" alt="Milkshake S" className="SongShakeMilkshake" />
          </span>
          <span className="title_shake">HAKE</span>
        </div>
      </div>
    </div>
  );
}

export default Logo;