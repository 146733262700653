import React from 'react';
import '../styles/IntroPage.css';  // Import styles
import { FaUser } from 'react-icons/fa';
import Logo from './Logo';

function IntroPage({ onLoginClick }) {
  return (
    <div className="intro-page">
        <div className="intro-page-container">
          <Logo />

          <span className="slogan">Turn Requests Into Reality<br />
            <span className='fade1'>Your Song</span>
            <span className='fade2'>, Your Stage.</span>
          </span>

          <div className="world-stage-container">
            <img src="/assets/images/WorldStage-nobg.png" alt="World Stage" className="world-stage-image" />
          </div>
          
          <div>
            <button className="login-button" onClick={onLoginClick}>
              <div className="login-button-container">
                <span className="login-icon-container">
                  <FaUser className="login-icon" />
                </span>
                <span className="login-text">Login</span>
              </div>
            </button>
          </div>
        </div>

    </div>
  );
}

export default IntroPage;
