import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMusic, FaUsers, FaMapMarkerAlt, FaUser, FaUsersCog, FaBook } from 'react-icons/fa';
import { RiCommunityLine } from 'react-icons/ri';
import '../styles/MainNavigation.css';  // Import styles

function MainNavigation( { profileImageURL }) {
  const navigate = useNavigate();

  // Check sessionStorage if profileImageURL is not passed as a prop
  if (!profileImageURL) {
    profileImageURL = sessionStorage.getItem('ProfileImageURL');
  }
  
  return (
    <div className="main-navigation">
      <div className="search-buttons">
        <button className="search-button" onClick={() => navigate('/performers')}>
          <FaUsers className="search-icon" />
          <span>Performers</span>
        </button>
        <button className="search-button">
          <RiCommunityLine className="search-icon" />
          <span>Community</span>
        </button>
        <button className="search-button">
          <FaUsersCog className="search-icon" />
          <span>Connections</span>
        </button>
        <button className="search-button">
          <FaMapMarkerAlt className="search-icon" />
          <span>Nearby</span>
        </button>
        <button className="search-button">
          <FaMusic className="search-icon" />
          <span>Songs</span>
        </button>
        <button className="search-button">
          <FaBook className="search-icon" />
          <span>Learn</span>
        </button>
        <button className="search-button" onClick={() => navigate('/profile')}>
            {profileImageURL ? (
                <img src={profileImageURL} alt="Profile" className="navigation-profile-image" />
            ) : (
                <FaUser className="home-page-search-icon" />
            )}
          <span>Profile</span>
        </button>
      </div>
    </div>
  );
}

export default MainNavigation;