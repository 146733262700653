import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import '../styles/HomePage.css';  // Import styles
import MainNavigation from './MainNavigation';
import TopPageNavigation from './TopPageNavigation';

function HomePage() {
    const [profileImageURL, setProfileImageURL] = useState(null);
    
  
    useEffect(() => {
      sessionStorage.getItem('ProfileImageURL', profileImageURL);
      setProfileImageURL(profileImageURL);
    }, [profileImageURL]);

    return (
      <div className="home-page">
        <div className="home-page-header"><Logo /></div>
        <div className="home-page-wrapper">
          <TopPageNavigation />
          <div className="home-page-featured-area">
              <div className="home-page-notification-area">
                  <h2>Keeping you in the know:</h2>
                  <div className="home-page-notification">
                      <div className="home-page-notification-username">User1</div>
                      <div className="home-page-notification-message">This is a sample notification message.</div>
                  </div>
                  <div className="home-page-notification">
                      <div className="home-page-notification-username">User2</div>
                      <div className="home-page-notification-message">Another sample notification message.</div>
                  </div>
              </div>
              <MainNavigation profileImageURL={profileImageURL} /> 
          </div>
        </div>
      </div>
    );
}

export default HomePage;