import React from 'react';
import { FaBell, FaRegCommentDots  } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/TopPageNavigation.css';  // Import styles

function TopPageNavigation() {
  const navigate = useNavigate();

  const handleNotificationClick = () => {
    navigate('/home');
  };
  return (
    <div className="top-page-navigation">
      <div className="top-page-navigation-left">
        <button className="top-page-nav-button">Engagement Tier: Listener</button>
        <button className="top-page-nav-button">Credits: 42</button>
      </div>

      <div className="top-page-navigation-right">
        <button className="top-page-nav-button" aria-label="Notifications" onClick={handleNotificationClick}>
          <FaBell className="top-page-nav-icon" />
        </button>
        <button className="top-page-nav-button" aria-label="Submit Feedback">
          <FaRegCommentDots  className="top-page-nav-icon" />
        </button>
      </div>
    </div>
  );
}

export default TopPageNavigation;